import styled from 'styled-components'
import { rgba } from 'polished'
import { media } from '@superlogica/design-ui'
import { parseThemeColor } from 'utils'

export const Iframe = styled.iframe``

export const PlayButton = styled.button`
  margin-right: 60px;
  transition: all ease 400ms;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 24px;
  background-color: transparent;

  ${({ theme }) => `
    border: 4px solid ${rgba(parseThemeColor('babar0', theme), 0.5)};
  `}

  ${media.lessThan('sm')} {
    margin-bottom: 0;
    padding: 16px;
    width: 60px;
    height: 60px;
  }
`

export const Poster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  transition: all ease 1s;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
`

export const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
`

export const Wrapper = styled.div`
  position: relative;
  display: block;
  contain: content;
  cursor: pointer;
  overflow: hidden;

  ${({ theme }) => `
    background-color: ${parseThemeColor('babar1000', theme)};
  `}

  &.player-activated {
    ${Poster} {
      opacity: 0;
      visibility: hidden;
      user-select: none;
    }
  }

  &:hover {
    ${PlayButton} {
      transform: scale(1.1);
    }
  }
`
