import * as React from 'react'
import { Block, Text } from '@superlogica/design-ui'

import * as S from './Footer.styled'

interface CopyrightProps {
  items?: {
    href: string
    label: string
    target?: TargetProps
  }[]
}

const Copyright: React.FC<CopyrightProps> = ({ items = [] }) => {
  const date = new Date()

  return (
    <S.Copyright>
      <Block
        display="flex"
        alignItems={{ xxxs: 'flex-start', md: 'center' }}
        flexDirection={{ xxxs: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
      >
        <Text weight="light" size="label04">
          Copyright © {date.getFullYear()} Superlógica Tecnologias S.A. Todos os
          direitos reservados. CNPJ nº 04.833.541/0001-51
        </Text>
        <S.Nav>
          <S.List>
            {items.map(({ href, label, ...restUrl }) => (
              <S.Item key={label}>
                <S.NavLink to={href} title={label} {...restUrl}>
                  {label}
                </S.NavLink>
              </S.Item>
            ))}
          </S.List>
        </S.Nav>
      </Block>
    </S.Copyright>
  )
}

export default Copyright
