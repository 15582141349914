import styled, { css, keyframes } from 'styled-components'
import { linearGradient, rgba } from 'polished'
import { parseColor } from '@superlogica/design-ui'

const shimer = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  width: var(--skeleton-width);
  height: var(--skeleton-height);
  border-radius: 3px;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar75)};

    &:after {
      ${linearGradient({
        colorStops: [
          `${rgba(parseColor(colors.babar0), 0)} 0%`,
          `${rgba(parseColor(colors.babar0), 0.2)} 20%`,
          `${rgba(parseColor(colors.babar0), 0.5)} 60%`,
          `${rgba(parseColor(colors.babar0), 0)}`
        ],
        toDirection: '90deg',
        fallback: 'transparent'
      })}
    }
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: ${shimer} 4s infinite;
    transform: translateX(-100%);
  }
`
