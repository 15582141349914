import React from 'react'
import { Link } from 'components/helpers'
import { Button, Vector } from '@superlogica/design-ui'

import * as S from './ActionList.styled'

export interface ActionListProps {
  color?: Color
  items?: UrlProps[]
  marginTop?: string | number
  marginRight?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
}

const ActionList: React.FC<ActionListProps> = ({
  color,
  items = [],
  ...rest
}) => (
  <S.Wrapper {...rest}>
    {items.map(({ href, label, vector, ...button }) => {
      let actionProps: any = { ...button }

      const buttonAppearance = button.appearance || 'solid'
      const buttonColor = button.color || color || 'sonic500'

      if (vector) {
        actionProps.rightIcon = <Vector name={vector} />
      }

      return (
        <S.Item key={label}>
          <Button
            to={href}
            color={buttonColor}
            appearance={buttonAppearance}
            title={label}
            tag={Link}
            iconSize={10}
            weight="semiBold"
            {...actionProps}
          >
            {label}
          </Button>
        </S.Item>
      )
    })}
  </S.Wrapper>
)

export default ActionList
