import * as React from 'react'
import { Helmet, HelmetProps } from 'react-helmet'
import { useSiteMetadata } from 'hooks'

export interface SeoProps extends HelmetProps {
  cover?: string
  description?: string
  lang?: string
  title?: string
  defaultTitle?: string
  meta?: { name: string; content: string }[]
}

const Seo: React.FC<SeoProps> = ({
  cover,
  description,
  lang = `pt-br`,
  title,
  defaultTitle,
  meta = []
}) => {
  const siteMetadata = useSiteMetadata()

  return (
    <Helmet
      htmlAttributes={{ lang }}
      defaultTitle={defaultTitle || siteMetadata.title}
      titleTemplate={`%s - ${siteMetadata.title}`}
      title={title}
      meta={[
        {
          name: `description`,
          content: description || undefined
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description || undefined
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: `${siteMetadata.siteUrl}/${cover || siteMetadata.cover}`
        }
      ].concat(meta)}
    />
  )
}

export default Seo
