import * as React from 'react'
import { ThemeProvider } from '@superlogica/design-ui'
import { PageProps } from 'gatsby'

import vectors from 'assets/svg/vectors'
import { GlobalStyle, theme } from 'styles'
import { ConfigProvider } from 'contexts'
import { Footer, Header } from 'components/common'

import * as S from './Default.styles'

const DefaultLayout: React.FC<PageProps> = ({ children }) => (
  <ConfigProvider>
    <ThemeProvider theme={theme} svgLibrary={{ vectors }}>
      <GlobalStyle />
      <Header />
      <S.Main role="main">{children}</S.Main>
      <Footer />
    </ThemeProvider>
  </ConfigProvider>
)

export default DefaultLayout
