import styled, { css } from 'styled-components'
import { mapCSSPropsToStyled, media } from '@superlogica/design-ui'
import { ActionListProps } from './ActionList'

type WrapperProps = Omit<ActionListProps, 'items'>

export const Item = styled.li`
  &:not(:last-of-type) {
    margin-right: 12px;
  }

  ${media.lessThan('xxs')} {
    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 12px;
    }

    a,
    button {
      width: 100%;
    }
  }
`

export const Wrapper = styled.ul.withConfig({
  shouldForwardProp: (prop) =>
    !['marginTop', 'marginRight', 'marginBottom', 'marginLeft'].includes(
      prop as string
    )
})<WrapperProps>`
  display: flex;

  ${media.lessThan('xxs')} {
    flex-direction: column;
  }

  ${({ marginTop, marginRight, marginBottom, marginLeft }) => css`
    ${mapCSSPropsToStyled({
      marginTop,
      marginRight,
      marginBottom,
      marginLeft
    })}
  `}
`
