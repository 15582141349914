import { object, string, boolean } from 'yup'

export const schema = object({
  firstname: string().required('campo obrigatório'),
  email: string().required('campo obrigatório').email('e-mail inválido'),
  phone: string()
    .required('campo obrigatório')
    .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'telefone inválido'),
  company: string().required('campo obrigatório'),
  administradora_sindico_ou_morador: string().required('campo obrigatório'),
  cliente_superlogica: string().required('campo obrigatório'),
  motivo_do_contato: string().required('campo obrigatório'),
  condo__acelera__aceita_do_programa: boolean().oneOf([true])
})
