import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Vector } from '@superlogica/design-ui'

import { Tile } from 'components/app'
import { VectorsProps } from 'assets/svg/vectors'

import * as S from './styled'

interface FeaturesDataProps {
  allFeaturesJson: {
    nodes: {
      title: string
      icon: VectorsProps
    }[]
  }
}

const SectionGrowth = () => {
  const {
    allFeaturesJson: { nodes: features }
  } = useStaticQuery<FeaturesDataProps>(
    graphql`
      query {
        allFeaturesJson(filter: { category: { eq: "growth" } }) {
          nodes {
            title
            icon
          }
        }
      }
    `
  )

  return (
    <S.SectionGrowth>
      <Container>
        <Block
          tag="header"
          display="flex"
          alignItems={{ xxxs: 'normal', md: 'center' }}
          flexDirection={{ xxxs: 'column', md: 'row' }}
          position="relative"
          marginBottom={40}
        >
          <S.InnerWrapper>
            <Block
              display="flex"
              alignItems="center"
              marginBottom={{ xxxs: 0, md: 8 }}
            >
              <Vector
                name="plus-square"
                width={84}
                height={84}
                marginRight={{ xxxs: 16, md: 24 }}
                color="warning"
              />
              <Text color="warning" font="heading" size="heading">
                15%
              </Text>
            </Block>
            <Text color="warning" font="heading" size="display01">
              de crescimento
            </Text>
          </S.InnerWrapper>
          <S.Wrapper>
            <Title font="heading" size="display04" tag="h2" color="trooper900">
              É o resultado que os participantes do Acelera tiveram em 2023 e
              essas são as ações que eles colocaram em prática
            </Title>
          </S.Wrapper>
        </Block>
        <Block
          tag="article"
          display="grid"
          gridTemplateColumns={{
            xxxs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(3, 1fr)'
          }}
          columnGap={{ xxxs: 0, sm: '32px', md: '48px', lg: '84px' }}
          rowGap={{ xxxs: '16px', sm: '32px' }}
        >
          {features.map(({ title, icon }, index) => (
            <Tile key={index} vector={icon} color="warning" dark>
              {title}
            </Tile>
          ))}
        </Block>
      </Container>
    </S.SectionGrowth>
  )
}

export default SectionGrowth
