import React from 'react'

import { appConfig } from 'config'
import { Config, Seo } from 'components/helpers'

import View from 'views/thanks'

const IndexPage: React.FC = () => (
  <>
    <Config data={appConfig} />
    <Seo
      title="Obrigado"
      description="Parabéns! Sua pré-inscrição para o Superlógica Acelera foi realizada."
    />
    <View />
  </>
)

export default IndexPage
