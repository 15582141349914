import styled from 'styled-components'
import { rem } from 'polished'
import { media, parseColor } from '@superlogica/design-ui'

export const Header = styled.header`
  padding: 60px 0;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.violet400)};
  `}

  ${media.lessThan('sm')} {
    padding: 40px 0;
  }
`

export const Title = styled.h2`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${rem(16)};
  margin-bottom: 24px;
  &:before {
    content: counter(item);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    font-size: ${rem(14)};
    line-height: 1;
    font-weight: 700;
    margin-right: 16px;
  }
  ${({ theme: { fonts, colors } }) => `
    font-family: ${fonts.heading}, sans-serif;
    &:before {
      background-color: ${parseColor(colors.violet400)};
      color: ${parseColor(colors.babar0)};
    }
  `}
`

export const Item = styled.li`
  a {
    font-weight: 700;
  }

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`

export const SubList = styled.ul`
  padding-left: 30px;

  ${Item} {
    padding-left: 20px;
    list-style-type: disc;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
`

export const List = styled.ol`
  > ${Item} {
    counter-increment: item;
  }
`
