import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button } from '@superlogica/design-ui'

import { Planes } from 'components/app'

import * as S from './styled'
import { Link } from 'components/helpers'

const SectionConversion = () => (
  <S.SectionConversion>
    <S.Background>
      <StaticImage
        src="../../assets/images/home/bg-conversion.png"
        quality={95}
        objectFit="cover"
        objectPosition="center"
        style={{ width: '100%', height: '100%' }}
        alt="bg"
      />
    </S.Background>
    <Container>
      <Block tag="article" maxWidth={640}>
        <Title
          color="babar0"
          secondColor="warning"
          font="heading"
          size="display02"
          weight="bold"
          marginBottom={32}
          tag="h2"
        >
          Faça parte do seleto grupo de administradoras que vai{' '}
          <span>decolar</span> em 2024!
        </Title>
        <Text weight="semiBold" color="trooper0" marginBottom={32}>
          Inscreva-se e fique de olho na sua caixa de e-mails, em breve você vai
          receber o calendário dos encontros e descobrir muitas novidades.
        </Text>
        <Block
          display="flex"
          alignItems={{ xxxs: 'flex-start', md: 'center' }}
          flexDirection={{ xxxs: 'column', md: 'row' }}
          gap={16}
        >
          <Button
            tag={Link}
            title="Faça a sua pré-inscrição!"
            to="#fazer-inscricao"
            color="warning"
            weight="semiBold"
            marginRight={16}
            size="lg"
          >
            Faça já sua inscrição
          </Button>
          <Text color="babar0" size="label02" uppercase>
            Vagas Limitadas
          </Text>
        </Block>
      </Block>
    </Container>
    <Planes name="planes-conversion" />
  </S.SectionConversion>
)

export default SectionConversion
