import React from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { navigate } from 'gatsby'
import {
  Button,
  dataLayerEvent,
  hubspotService,
  Input,
  Select,
  Accept,
  Text
} from '@superlogica/design-ui'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { schema } from './EnaconForm.util'
import { Link } from 'components/helpers'

export type EnaconFormValues = {
  firstname: string
  email: string
  phone: string
  company: string
  administradora_sindico_ou_morador: string
  cliente_superlogica: string
  motivo_do_contato: string
  condo__acelera__aceita_do_programa: boolean
}

interface EnaconFormProps {
  hubspotId: string
  pageName: string
  googleEvent?: string
}

const ProductEnaconForm: React.FC<EnaconFormProps> = ({
  hubspotId,
  pageName,
  googleEvent
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<EnaconFormValues>({
    resolver: yupResolver(schema)
  })

  const onSubmitForm: SubmitHandler<EnaconFormValues> = async (data) => {
    const resp = await hubspotService.sendForm({
      formId: hubspotId,
      data,
      pageName
    })

    if (resp.status !== 200) {
      return
    }

    if (!!googleEvent) {
      dataLayerEvent(googleEvent)
    }

    reset()

    return navigate('/obrigado')
  }

  return (
    <form id="form-conversion" noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Row>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('firstname')}
            id="conversion-firstname"
            label="Seu nome"
            errors={errors}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            className="mb-sm"
            {...register('email')}
            id="conversion-email"
            type="email"
            label="E-mail"
            mask="lowercase"
            errors={errors}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            className="mb-sm"
            {...register('phone')}
            id="conversion-phone"
            type="tel"
            label="Telefone"
            mask="phone"
            maxLength={15}
            errors={errors}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            className="mb-sm"
            {...register('company')}
            id="conversion-company"
            label="Nome da Empresa"
            errors={errors}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Select
            className="mb-sm"
            {...register('administradora_sindico_ou_morador')}
            id="conversion-administradora_sindico_ou_morador"
            label="Você é um(a)...?"
            errors={errors}
            options={[
              'Administradora',
              'Imobiliária',
              'Síndico',
              'Autogestão',
              'Morador'
            ]}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Select
            className="mb-sm"
            {...register('cliente_superlogica')}
            id="conversion-cliente_superlogica"
            label="Já é cliente Superlógica?"
            errors={errors}
            options={['Sim', 'Não']}
          />
        </Col>

        <Col xs={12} sm={6}>
          <Select
            className="mb-sm"
            {...register('motivo_do_contato')}
            id="conversion-motivo_do_contato"
            label="Você está no Enacon 2024?"
            errors={errors}
            options={[
              { value: 'Saber mais sobre preços', name: 'Sim' },
              { value: 'Sobre o meu ingresso', name: 'Não' }
            ]}
          />
        </Col>

        {/* @ts-ignore */}
        <Col xs={12} className="mb-xs">
          <Accept
            id="conversion-accept_terms"
            {...register('condo__acelera__aceita_do_programa')}
            value="1"
            errors={errors}
          >
            Li e aceito os{' '}
            <Button
              tag={Link}
              to="https://acelera.superlogica.com/termos-de-uso"
              color="violet500"
              appearance="link"
              target="_blank"
              rel="noopener noreferrer"
              title="termos de uso"
            >
              termos de uso
            </Button>
            ,{' '}
            <Button
              tag={Link}
              to="https://acelera.superlogica.com/regulamento"
              color="violet500"
              target="_blank"
              rel="noopener noreferrer"
              appearance="link"
              title="regulamento"
            >
              regulamento
            </Button>
            ,{' '}
            <Button
              tag={Link}
              to="https://sebrae.contatosebraesp.com.br/l/VrLRfdABF1115"
              color="violet500"
              appearance="link"
              target="_blank"
              rel="noopener noreferrer"
              title="termo de consentimento"
            >
              termo de consentimento
            </Button>
            , e{' '}
            <Button
              tag={Link}
              to="https://minio-cpe.sebrae.com.br/documento/politica-privacidade.pdf"
              color="violet500"
              appearance="link"
              target="_blank"
              rel="noopener noreferrer"
              title="tratamento de dados"
            >
              tratamento de dados
            </Button>
          </Accept>
        </Col>
        <Col xs={12}>
          <Button
            color="warning"
            type="submit"
            weight="semiBold"
            isLoading={isSubmitting}
            fluid={true}
          >
            Realizar minha pré-inscrição
          </Button>
        </Col>
        <Col xs={12}>
          <Text size="label03" marginTop={20}>
            Finalizando o preenchimento da sua inscrição/participação, você
            autorizará o compartilhamento de seus dados com a Superlógica e com
            as empresas parceiras, conforme a Lei Geral de Proteção a Dados
            Pessoais (Lei Nº 13.709, de 14 de agosto de 2018).
          </Text>
        </Col>
      </Row>
    </form>
  )
}

export default ProductEnaconForm
