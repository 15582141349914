import * as React from 'react'

import SectionIntro from './Section.Intro'
import SectionAbout from './Section.About'
import SectionNumbers from './Section.Numbers'
import SectionTestimonies from './Section.Testimonies'
import SectionVideo from './Section.Video'
import SectionGrowth from './Section.Growth'
import SectionEvents from './Section.Events'
import SectionConversion from './Section.Conversion'
import SectionSchedule from './Section.Schedule'

const View: React.FC = () => (
  <>
    <SectionIntro />
    <SectionAbout />
    <SectionNumbers />
    <SectionSchedule />
    <SectionTestimonies />
    <SectionVideo />
    <SectionGrowth />
    <SectionEvents />
    <SectionConversion />
  </>
)

export default View
