import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Block, Button, Text, Title, Vector } from '@superlogica/design-ui'

import { Planes } from 'components/app'
import { Link } from 'components/helpers'

import * as S from './styled'

const SectionError = () => (
  <S.SectionError>
    <S.Background>
      <StaticImage
        src="../../assets/images/home/bg-hero.png"
        quality={95}
        objectFit="cover"
        objectPosition="center"
        style={{ width: '100%', height: '100%' }}
        alt="bg"
      />
    </S.Background>
    <Planes name="planes-intro" />
    <Container>
      <S.InnerWrapper>
        <Row align="center" justify="space-between">
          <Col xs={12} md={6}>
            <Block tag="header" marginBottom={{ xxxs: 40, md: 0 }}>
              <Vector
                name="superlogica-acelera"
                width="auto"
                height={{ xxxs: 60, md: 120 }}
                marginBottom={{ xxxs: 48, md: 64 }}
              />
              <Title
                size="display02"
                font="heading"
                tag="h1"
                color="babar0"
                weight="bold"
                marginTop={8}
                marginBottom={24}
              >
                Página não encontrada
              </Title>
              <Text color="babar0" marginBottom={24}>
                Desculpe, mas a página que você está procurando não foi
                encontrada.
              </Text>
              <Button
                tag={Link}
                title="Voltar para o início"
                color="warning"
                weight="semiBold"
                to="/"
              >
                Voltar para o início
              </Button>
            </Block>
          </Col>
        </Row>
      </S.InnerWrapper>
    </Container>
  </S.SectionError>
)

export default SectionError
