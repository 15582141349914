import { object, string, boolean } from 'yup'

export const schema = object({
  firstname: string().required('campo obrigatório'),
  email: string().required('campo obrigatório').email('e-mail inválido'),
  phone: string()
    .required('campo obrigatório')
    .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'telefone inválido'),
  licenca: string().required('campo obrigatório'),
  cnpj: string().required('campo obrigatório'),
  cargo__temp_: string().required('campo obrigatório'),
  como_voce_ficou_sabendo_do_acelera_: string().required('campo obrigatório'),
  condo__acelera__aceita_do_programa: boolean().oneOf([true])
})
