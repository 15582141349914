import React from 'react'

import { appConfig } from 'config'
import { Config, Seo } from 'components/helpers'

import View from 'views/termos'

const IndexPage: React.FC = () => (
  <>
    <Config data={appConfig} />
    <Seo title="Termos de uso" />
    <View />
  </>
)

export default IndexPage
