import * as React from 'react'
import { Button, Text, Vector } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'

import * as S from './Header.styled'

const Header: React.FC = () => (
  <S.Wrapper id="inicio">
    <Container>
      <S.Content>
        <S.Brand>
          <Vector name="brand-color" width="100%" height="100%" />
        </S.Brand>
        <S.Login>
          <Text size="body02" weight="semiBold" lineHeight={1.01}>
            Universidade Condominial
          </Text>
          <Button
            tag="a"
            href="https://universidadecondominial.skore.io/"
            target="_blank"
            title="Acessar Portal Universidade Condominial"
            size="sm"
            color="warning"
            weight="semiBold"
            leftIcon={<Vector name="user" />}
          >
            Acessar Portal
          </Button>
        </S.Login>
      </S.Content>
    </Container>
  </S.Wrapper>
)

export default Header
