import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button } from '@superlogica/design-ui'
import { StaticImage } from 'gatsby-plugin-image'

import * as S from './styled'

const SectionEvents = () => (
  <S.SectionEvents>
    <Container>
      <Block
        tag="article"
        display="grid"
        gridTemplateColumns={{
          xxxs: '1fr',
          md: 'repeat(2, 1fr)'
        }}
        columnGap="32px"
        rowGap="32px"
      >
        <Block
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={16}
          bgColor="babar0"
          borderRadius={12}
          padding={24}
        >
          <Block>
            <Block marginBottom={24}>
              <StaticImage
                src="../../assets/images/home/banner-next.png"
                objectFit="cover"
                objectPosition="center top"
                style={{ width: '100%', height: '100%' }}
                alt="Banner Next"
              />
            </Block>
            <Title
              font="heading"
              color="trooper900"
              size="display02"
              weight="medium"
              marginBottom={16}
              tag="h3"
            >
              Um ecossistema que pulsa com conexões e aprendizado
            </Title>
            <Text>
              O Acelera marcou presença no Superlógica Next, o maior evento do
              mercado condominial. Em um estande dinâmico, com ativações como a
              Bike Acelera, os participantes concorreram a prêmios e estreitaram
              ainda mais as relações com um grande intercâmbio de aprendizados.
              Confira abaixo como foi o Superlógica Next 2023 e fique por dentro
              do que vem aí.
            </Text>
          </Block>
          <Button
            tag="a"
            href="https://next.superlogica.com"
            title="Saiba mais"
            target="_blank"
            color="warning"
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </Block>
        <Block
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bgColor="babar0"
          borderRadius={12}
          padding={24}
          gap={16}
        >
          <Block>
            <Block marginBottom={24}>
              <StaticImage
                src="../../assets/images/home/banner-experts.png"
                objectFit="cover"
                objectPosition="center top"
                style={{ width: '100%', height: '100%' }}
                alt="Banner Next"
              />
            </Block>
            <Title
              font="heading"
              color="trooper900"
              size="display02"
              weight="medium"
              marginBottom={16}
              tag="h3"
            >
              Não basta acelerar,
              <br /> tem que ser Expert
            </Title>
            <Text>
              O Superlógica Acelera faz parte do programa que está alavancando
              os resultados das administradoras de condomínio de todo o Brasil!
            </Text>
            <Text>
              Conheça o Superlógica Experts, que transformou a Superlógica na
              plataforma parceira do mercado condominial e agora está disponível
              gratuitamente para sua administradora.
            </Text>
          </Block>
          <Button
            tag="a"
            href="https://superlogica.com/experts/"
            title="Saiba mais"
            target="_blank"
            color="warning"
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </Block>
      </Block>
    </Container>
  </S.SectionEvents>
)

export default SectionEvents
