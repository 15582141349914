import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'

import * as S from './styled'

const View: React.FC = () => (
  <Block tag="section" bgColor="babar25">
    <S.Header>
      <Container>
        <Row justify="center">
          <Col xs={12} md={10}>
            <Title size="display02" font="heading" color="babar0">
              Regulamento do Programa Superlógica Acelera 2023
            </Title>
          </Col>
        </Row>
      </Container>
    </S.Header>
    <Block
      tag="article"
      paddingTop={{ xxxs: 40, md: 60 }}
      paddingBottom={{ xxxs: 40, md: 60 }}
    >
      <Container>
        <Row justify="center">
          <Col xs={12} md={10}>
            <S.List>
              <S.Item>
                <S.Title>EMPRESA PROMOTORA:</S.Title>
                <Text marginBottom={20}>
                  <b>SUPERLÓGICA TECNOLOGIA S/A,</b> Alameda Rio Negro, 585 -
                  Bloco B - 3º andar - Alphaville, na Cidade de Barueri, Estado
                  de São Paulo, CEP: 06454-000, inscrita no CNPJ/MF sob o no
                  04.833.541/0001-51 e suas CONTROLADAS.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>MODALIDADE DO PROGRAMA:</S.Title>
                <Text marginBottom={20}>
                  O Acelera é um programa de aceleração gratuito disponibilizado
                  em parceria com o SEBRAE para as Administradoras de Condomínio
                  parceiras da Superlógica.
                </Text>
                <Text>
                  Tem como objetivo principal capacitar as administradoras a
                  acelerar seus indicadores, adquirir habilidades e ferramentas
                  para escalar seus negócio.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>ÁREA DE ABRANGÊNCIA:</S.Title>
                <Text marginBottom={20}>3.1 - Território nacional</Text>
              </S.Item>
              <S.Item>
                <S.Title>PERÍODO DE INSCRIÇÃO:</S.Title>
                <Text marginBottom={20}>
                  4.1. - As inscrições para participar do Superlógica Acelera
                  poderão ser feitas no período de 01 de julho de 2023 até às
                  23h59min de 18 de outubro de 2023 (o “Período de
                  Pré-Inscrição”). As inscrições deverão ser feitas através da
                  página do Sympla do programa (Link da página) com o
                  preenchimento de todos os campos obrigatórios do formulário,
                  sendo feita por ou com a anuência do(s) representante(s)
                  legal(is) das Administradoras. Não serão aceitas inscrições
                  realizadas fora do Período de Inscrição ou em formato
                  diferente do aqui exposto.
                </Text>
                <Text>
                  4.2. - A administradora parceira do grupo Superlógica poderá
                  inscrever um ou mais participantes no programa tendo em vista
                  que as consultorias só serão realizadas para um CNPJ/Licença
                  da base de clientes parceiros da Superlógica.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>PERÍODO DO PROGRAMA:</S.Title>
                <Text marginBottom={20}>
                  5.1. - Os módulos acontecerão no período de julho a novembro
                  de 2023 <br /> Os módulos do Superlógica Acelera ocorrerá no
                  Espaço virtual, transmitido através do meet.google onde apenas
                  os clientes que possuem a inscrição validada e confirmada
                  terão acesso ao link, sendo apresentados conteúdos pertinentes
                  às trilhas de conhecimento: Marketing, Vendas, Planejamento
                  Estratégico e Operações através de aulas ministradas por
                  consultores do SEBRAE e especialistas do assunto da
                  Superlógica.
                </Text>
                <Text marginBottom={20}>
                  5.2. - Os participantes receberão acesso a plataforma de
                  conteúdos da Superlógica, onde terão acesso a materiais
                  complementares e trilhas de conhecimento, além de acesso aos
                  encontros ministrados pelos especialistas da Superlógica.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>CONSULTORIAS INDIVIDUAIS:</S.Title>
                <Text>
                  6.1 - Cada administradora terá o direito a realizar 2h (horas)
                  de consultoria online individual com o especialista do SEBRAE
                  durante os encontros, a data e horário será definida
                  diretamente com o SEBRAE.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>CRITÉRIO DE PARTICIPAÇÃO:</S.Title>
                <Text marginBottom={20}>
                  7.1 - O Programa Superlógica Acelera é instituído pela Empresa
                  Promotora e suas Controladas, com realização em todo
                  território nacional, no Período de Participação mencionado no
                  Item 5, programa exclusivo para clientes Superlógica e
                  empresas do grupo.
                </Text>
                <Text marginBottom={20}>
                  7.2 - Sem prejuízo das demais condições estabelecidas neste
                  Regulamento, considera-se participante deste programa
                  “Superlógica Acelera” (“Participantes”), os Clientes que se
                  cadastraram previamente na página do Sympla{' '}
                  <a href="https://www.sympla.com.br/evento-online/superlogica-acelera/2042789">
                    https://www.sympla.com.br/evento-online/superlogica-acelera/2042789
                  </a>
                  , durante o Período de inscrição, concomitantemente, atenda
                  aos seguintes requisitos:
                </Text>
                <Text marginBottom={20}>
                  (i) Os Participantes que se cadastrarem na landing page,
                  através do site acelera.superlogica.com terão a sua
                  pré-inscrição enviada para análise, onde os critérios da
                  comissão interna.
                </Text>
                <Text marginBottom={20}>
                  100% das vagas são destinadas a clientes ativos do grupo
                  Superlógica, seguindo a ordem de critérios a seguir:
                </Text>
                <S.SubList>
                  <S.Item>Clientes denominados agência</S.Item>
                  <S.Item>
                    Clientes do grupo Superlógica e demais clientes presentes em
                    nossa base.
                  </S.Item>
                </S.SubList>
                <Text marginTop={20} marginBottom={20}>
                  O Participante deverá cadastrar seu nome, e-mail, whatsapp,
                  CNPJ da administradora, nome da empresa, posição na empresa
                  (diretoria, gerência, operações), Cidade/Estado, se já
                  participou do Acelera em edições anteriores e se já foi ao
                  Superlógica Next em anos anteriores.
                </Text>
                <Text marginBottom={20}>
                  7.3 - Cada administradora tem o direito de cadastrar até 5
                  (cinco) participantes.
                </Text>
                <Text marginBottom={20}>
                  7.4 - Será considerado o horário de Brasília para todos os
                  períodos descritos neste Regulamento.
                </Text>
                <Text>
                  7.5 - Os certificados podem ser emitidos até 60 dias após o
                  término completo do programa (último encontro 20/10/2023 -
                  ultimo dia para emissão dos certificados 20/12/2023).
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>CRITÉRIOS DE DESCLASSIFICAÇÃO:</S.Title>
                <Text marginBottom={20}>
                  8.1 - Os pré-inscritos serão excluídos automaticamente do
                  programa em caso de fraude comprovada, podendo ainda responder
                  por crime de falsidade ideológica ou documental.
                </Text>
                <Text>
                  8.2 - Os participantes poderão ter acesso encerrado na
                  plataforma e participação interrompida em caso de quebra de
                  contrato com a Superlógica ou empresas do grupo, não fazendo
                  mais parte da base de clientes ativos.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>
                  LICENÇA DE IMAGEM, USO DO NOME E DEMAIS AUTORIZAÇÕES
                </S.Title>
                <Text>
                  As Administradoras e os integrantes das Administradoras,
                  participantes do programa, autorizam expressamente a Promotora
                  a utilizarem, conforme seja o caso, sua marca, nome, logotipo,
                  imagem, som, dados biográficos e demais informações cedidas na
                  ficha de inscrição para fins educativos, estudos estatísticos,
                  econômicos, demográficos e outros, portfólios, demais
                  materiais destinados à divulgação do Superlógica Acelera. O
                  referido uso pode se dar na Página do Programa, mídias
                  sociais, bem como outros canais de divulgação da Fomentadora,
                  Organizadora e/ou de terceiros. Todas as Administradoras,
                  autorizam, ainda, a divulgação de sua participação no
                  programa, bem como de seus nomes, marcas, logotipos, negócios
                  produtos e/ou serviços pela Fomentadora e pela Organizadora em
                  diversos canais de comunicação, incluindo, mas não limitado, a
                  mídias sociais, jornais, revistas, etc. Diante das
                  autorizações acima, a Promotora poderá armazenar, editar,
                  usar, copiar, reproduzir e publicar a imagem das marcas ou
                  logotipos das Administradoras e a imagem dos Integrantes das
                  Administradoras tal como fotografada e/ou filmada (a “Imagem”)
                  a qualquer tempo, no Brasil ou no exterior, em toda e qualquer
                  mídia atualmente conhecida ou futuramente descoberta ou
                  desenvolvida. A Superlógica será a proprietária exclusiva de
                  todos os direitos autorais e outros direitos sobre as Imagens
                  e nenhum material que inclua as imagens deverá ser apresentado
                  às Administradoras.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>
                  PROPRIEDADE INTELECTUAL, TITULARIDADE E CONFIDENCIALIDADE
                </S.Title>
                <Text>
                  A Promotora não exige para participação no Superlógica Acelera
                  qualquer pagamento ou contrapartida de qualquer natureza, nem
                  mesmo qualquer montante ou participação relacionado aos
                  produtos e/ou serviços da Administradoras que serão
                  apresentados e/ou melhor desenvolvidos durante o Superlógica
                  Acelera Em diversos momentos do Superlógica Acelera, poderá
                  ser necessário que as Administradoras revelem aos consultores
                  algumas informações estratégicas a respeito do seu negócio,
                  propriedade intelectual e diferenciais de mercado da
                  Administradoras Participante. Embora os consultores que
                  participam do Superlógica Acelera firmam acordos de
                  confidencialidade, a Promotora não se responsabiliza caso haja
                  qualquer quebra da confidencialidade relacionada aos projetos,
                  produtos e/ou serviços, bem como qualquer outra informação da
                  Administradora e/ou dos Integrantes da Administradora
                  revelados durante o Superlógica Acelera. Ainda, a Fomentadora
                  e a Organizadora não se responsabilizam, em hipótese alguma,
                  pelo uso que terceiros farão das informações reveladas pelas
                  Administradoras e/ou dos Integrantes da Administradoras
                  durante o Superlógica Acelera. As Administradoras e os
                  Integrantes da Administradoras garantem que contam com a
                  titularidade exclusiva, livre de qualquer limitação, de todos
                  os direitos de propriedade intelectual e industrial sobre os
                  produtos e/ou serviços da Administradora. Assim, a Fomentadora
                  e a Organizadora não serão, em nenhum caso, responsáveis por
                  eventuais reclamações de terceiros, em relação a infrações de
                  direitos de propriedade industrial e direitos autorais de
                  qualquer tipo, respondendo as Administradoras e os Integrantes
                  da Administradoras, cível e criminalmente, pelos ilícitos que
                  vierem a cometer. Os Integrantes da Administradoras declaram e
                  garantem que as suas publicações em redes sociais, quando
                  relacionadas ao Superlógica Acelera, não terão o condão
                  comercial e farão referência única e exclusivamente ao fato
                  dos Integrantes da Administradoras estarem participando do
                  referido programa. Adicionalmente, os Integrantes da
                  Administradoras se comprometem a unicamente a postar imagens
                  originalmente suas, que não sejam ilegais ou contrárias à
                  ordem pública e aos bons costumes e que não infrinjam nenhum
                  direito de terceiro, outorgando neste ato à Fomentadora o
                  direito e licença, livre de royalties, irrestrito, mundial,
                  perpétuo, irrevogável, não-exclusivo e totalmente transferível
                  e sublicenciável, para usar, copiar, reproduzir, modificar,
                  adaptar, publicar, traduzir, criar trabalhos derivados,
                  distribuir, executar e divulgar tais publicações (no todo ou
                  em parte) e/ou incorporá-las em outros trabalhos em qualquer
                  forma, mídia ou tecnologia existente ou que venha a ser
                  desenvolvida. Qualquer divulgação sobre ou com relação ao
                  Superlógica Acelera, bem como da presença dos Integrantes da
                  Administradoras no mencionado programa, com viés ou propósito
                  comercial, exemplificativamente, mas não limitado à comunicado
                  à imprensa, dependerá de anuência prévia e por escrito da
                  Promotora Fomentadora.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>PRIVACIDADE E PROTEÇÃO DE DADOS</S.Title>
                <Text>
                  11.1 - O PARTICIPANTE declara estar ciente e concorda que a
                  PROMOTORA e suas Controladas poderão ter acesso a informações
                  que identifiquem ou possibilitem identificar pessoas naturais
                  (“Dados Pessoais”) em razão da promoção a qual o PARTICIPANTE
                  deseja se cadastrar. Os Dados Pessoais poderão, conforme o
                  caso, ser fornecidos diretamente pelo PARTICIPANTE e/ou
                  coletados e tratados pela PROMOTORA e suas Controladas sendo
                  utilizados para possíveis contatos futuros entre as partes.
                </Text>
              </S.Item>
              <S.Item>
                <S.Title>DISPOSIÇÕES GERAIS:</S.Title>
                <Text marginBottom={20}>
                  12.1 - Para participar do programa, o Participante deverá
                  aderir aos termos de uso do respectivo programa.
                </Text>
                <Text marginBottom={20}>
                  12.2 - O Participante contemplado concorda desde já, com a
                  utilização de seu nome, imagem e som de voz para divulgação do
                  programa, sem que isso traga qualquer tipo de ônus para
                  empresa Promotora por tempo indeterminado a partir da data da
                  apuração.
                </Text>
                <Text marginBottom={20}>
                  12.3 - A mera participação implica no conhecimento e
                  concordância, pelo Participante, de todas as condições
                  previstas neste Regulamento.
                </Text>
                <Text marginBottom={20}>
                  12.4 - Fica, desde já, eleito o foro central da Comarca de
                  Campinas - SP para solução de quaisquer questões referentes ao
                  Regulamento da presente Promoção.
                </Text>
              </S.Item>
            </S.List>
          </Col>
        </Row>
      </Container>
    </Block>
  </Block>
)

export default View
