import styled from 'styled-components'
import { parseColor } from '@superlogica/design-ui'

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`

export const InnerWrapper = styled.div`
  position: relative;
`

export const SectionIntro = styled.section`
  position: relative;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 60px 0;

  .planes {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5vw;
    margin: auto;
    width: 540px;
    height: 80%;
  }

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.violet300)};
  `}
`
