import React from 'react'

import { appConfig } from 'config'
import { Config, Seo } from 'components/helpers'

import View from 'views/home'

const IndexPage: React.FC = () => (
  <>
    <Config data={appConfig} />
    <Seo
      defaultTitle="Superlógica Acelera - Programa de Aceleração para Administradoras de Condomínios"
      description="Conheça o Superlógica Acelera, o primeiro programa de aceleração para administradoras de condomínios do Brasil. Faça a sua pré-inscrição!"
    />
    <View />
  </>
)

export default IndexPage
