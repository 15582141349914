import { parseSize } from '@superlogica/design-ui'
import React from 'react'

import * as S from './Skeleton.styled'

interface SkeletonProps {
  width?: number | string
  height?: number | string
}

const Skeleton: React.FC<SkeletonProps> = ({
  width = '100%',
  height = '100%'
}) => (
  <S.Wrapper
    style={{
      '--skeleton-width': parseSize(width),
      '--skeleton-height': parseSize(height)
    }}
  />
)

export default Skeleton
