import styled from 'styled-components'
import { media } from '@superlogica/design-ui'

export const Brand = styled.div`
  display: flex;
  align-items: center;
  width: 124px;
`

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;

  a {
    align-self: flex-end;
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  overflow: hidden;
`
