import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Block, Text, Title, Vector } from '@superlogica/design-ui'
import { Planes } from 'components/app'
import { ConversionForm } from 'components/forms'

import * as S from './styled'

const SectionIntro = () => (
  <S.SectionIntro>
    <S.Background>
      <StaticImage
        src="../../assets/images/home/bg-hero.png"
        quality={95}
        objectFit="cover"
        objectPosition="center"
        style={{ width: '100%', height: '100%' }}
        alt="bg"
      />
    </S.Background>
    <Container>
      <S.InnerWrapper>
        <Block
          display="grid"
          gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr' }}
          alignItems="center"
          gap={{ xxxs: 40, md: 60 }}
        >
          <Block
            tag="header"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            position="relative"
          >
            <Planes name="planes-hero-2" />
            <Vector
              name="superlogica-acelera"
              width={{ xxxs: 240, md: 347 }}
              height="auto"
              marginBottom={{ xxxs: 40, md: 80 }}
            />
            <Block
              bgColor="acelera900"
              display="inline-block"
              padding={{ xxs: '8px 20px', md: '8px 10px' }}
              borderRadius="50vh"
            >
              <Text uppercase color="babar0" weight="bold" size="label03">
                Exclusivo para clientes do grupo superlógica
              </Text>
            </Block>
            <Title
              size="display01"
              font="heading"
              tag="h1"
              color="babar0"
              weight="bold"
              marginTop={16}
              marginBottom={24}
            >
              Um novo programa para acelerar seu crescimento em 2024
            </Title>
            <Text color="babar0" weight="semiBold">
              O Acelera vem com um novo formato, com novos conteúdos e novos
              embaixadores para acelerar ainda mais o crescimento da sua
              administradora em 2024. Vamos juntos nessa!
            </Text>
          </Block>
          <Block
            tag="article"
            padding={{ xxxs: '0 0 40px', md: '80px 0 40px' }}
            id="fazer-inscricao"
          >
            <Block
              bgColor="babar0"
              padding={{ xxxs: 32, md: 40 }}
              borderRadius="8px 8px 0 0"
              zIndex={3}
              className="formWrapper"
            >
              <Title
                size="display03"
                font="heading"
                weight="bold"
                color="acelera500"
                textAlign="center"
                marginBottom={32}
              >
                Faça já sua inscrição
              </Title>
              <ConversionForm
                hubspotId="ff0c8583-cd5e-4ba5-8d67-c0f945b7e937"
                pageName="Superlógica Acelera - Home"
              />
            </Block>
          </Block>
        </Block>
      </S.InnerWrapper>
    </Container>
  </S.SectionIntro>
)

export default SectionIntro
