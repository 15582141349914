import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Block, Button, Text, Title, Vector } from '@superlogica/design-ui'

import { Planes } from 'components/app'
import { Link } from 'components/helpers'

import * as S from './styled'

const SectionIntro = () => (
  <S.SectionIntro>
    <S.Background>
      <StaticImage
        src="../../assets/images/home/bg-hero.png"
        quality={95}
        objectFit="cover"
        objectPosition="center"
        style={{ width: '100%', height: '100%' }}
        alt="bg"
      />
    </S.Background>
    <Planes name="planes-intro" />
    <Container>
      <S.InnerWrapper>
        <Row align="center" justify="space-between">
          <Col xs={12} md={6}>
            <Block tag="header" marginBottom={{ xxxs: 40, md: 0 }}>
              <Vector
                name="superlogica-acelera"
                width="auto"
                height={{ xxxs: 60, md: 120 }}
                marginBottom={{ xxxs: 48, md: 64 }}
              />
              <Title
                size="display02"
                font="heading"
                tag="h1"
                color="babar0"
                secondColor="warning"
                weight="bold"
                marginTop={8}
                marginBottom={24}
              >
                Parabéns! Sua pré-inscrição para o{' '}
                <span>Superlógica Acelera</span> foi realizada
              </Title>
              <Text color="babar0" marginBottom={24}>
                Para os próximos passos, preste atenção na sua caixa de e-mails.
                O número de vagas é limitado, por isso, assim que chegar a data
                limite das inscrições, faremos uma análise interna com base nas
                premissas do regulamento para determinar os participantes.
              </Text>
              <Button
                tag={Link}
                title="Termos de Uso do Superlógica Acelera"
                color="warning"
                weight="semiBold"
                to="/"
              >
                Termos de uso do Superlógica Acelera
              </Button>
            </Block>
          </Col>
        </Row>
      </S.InnerWrapper>
    </Container>
  </S.SectionIntro>
)

export default SectionIntro
