import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'
import { CardNumber } from 'components/app'

import * as S from './styled'

const SectionNumbers = () => (
  <S.SectionNumbers>
    <Container>
      <Block tag="header" marginBottom={40}>
        <Title
          font="heading"
          size="display02"
          color="trooper900"
          weight="bold"
          marginBottom={12}
          tag="h2"
        >
          Veja porque vale a pena acelerar
        </Title>
        <Text font="heading" size="display04" color="trooper900">
          Essa foi a diferença nos números de crescimento das administradoras 
        </Text>
      </Block>
      <Block
        tag="article"
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', lg: '1fr 1fr 1fr' }}
        gap={32}
        marginBottom={24}
      >
        <CardNumber title="Receita" numberUp="+15,94%" numberDown="+9,76%" />
        <CardNumber
          title="Condomínios Administrados"
          numberUp="+10,10%"
          numberDown="+1,11%"
        />
        <CardNumber title="Unidades" numberUp="+8,78%" numberDown="+1,36%" />
      </Block>
      <Text color="trooper900">*Crescimento comparativo de 2022 x 2023.</Text>
    </Container>
  </S.SectionNumbers>
)

export default SectionNumbers
