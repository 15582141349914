import { Block, Title, Text, Button } from '@superlogica/design-ui'
import { Planes, Player } from 'components/app'
import { Link } from 'components/helpers'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'

import * as S from './styled'

const SectionVideo = () => {
  return (
    <S.SectionVideo>
      <Planes name="planes-video" />
      <Planes name="planes-video2" />
      <Container>
        <Row align="center" justify="center">
          <Col md={10}>
            <Block tag="header" marginBottom={40}>
              <Title
                size="display02"
                color="acelera100"
                weight="bold"
                textAlign="center"
                font="heading"
                marginBottom={16}
                tag="h2"
              >
                Conhecimento que traz resultados
              </Title>
              <Text
                size="display04"
                font="heading"
                textAlign="center"
                color="babar0"
                weight="light"
              >
                Em 2023, o programa brilhou com muitas novidades e resultados
                excepcionais. Para esse ano, estamos preparando ainda mais
                novidades para impulsionar o crescimento da sua administradora.
              </Text>
            </Block>
            <Block>
              <Player
                video="floqvnRt3aY"
                poster={
                  <StaticImage
                    placeholder="blurred"
                    layout="fullWidth"
                    src="../../assets/images/home/thumb-video.png"
                    objectFit="cover"
                    objectPosition="center top"
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    alt="Vídeo falando sobre o Superlógica Acelera"
                  />
                }
              />
            </Block>
            <Block marginTop={40} textAlign="center">
              <Button
                tag={Link}
                title="Faça já sua inscrição"
                to="#fazer-inscricao"
                color="acelera100"
                weight="semiBold"
                marginRight={16}
                size="lg"
              >
                Faça já sua inscrição
              </Button>
            </Block>
          </Col>
        </Row>
      </Container>
    </S.SectionVideo>
  )
}

export default SectionVideo
