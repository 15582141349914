import React from 'react'
import { Block, Title, Text, Button } from '@superlogica/design-ui'
import { ScheduleCard } from 'components/app'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Link } from 'components/helpers'

import * as S from './styled'

interface ScheduleData {
  wp: {
    allAtendeSchedule: {
      nodes: {
        id: string
        title: string
        customFields: {
          data: string
          descricao: string
        }
      }[]
    }
  }
}

const SectionSchedule = () => {
  const {
    wp: { allAtendeSchedule }
  } = useStaticQuery<ScheduleData>(graphql`
    query {
      wp {
        allAtendeSchedule(
          where: { orderby: { field: MENU_ORDER, order: ASC } }
          first: 8
        ) {
          nodes {
            title
            customFields {
              data
              descricao
            }
          }
        }
      }
    }
  `)

  return (
    <S.SectionSchedule>
      <Container>
        <Title
          font="heading"
          size="display02"
          weight="semiBold"
          tag="h2"
          color="acelera100"
          marginBottom={8}
          textAlign="center"
        >
          Confira a programação
        </Title>
        <Text color="babar0" marginBottom={40} textAlign="center">
          <strong>*</strong> A programação pode sofrer alteração sem aviso
          prévio
        </Text>
        <Block
          tag="ul"
          display="grid"
          gridTemplateColumns={{
            xxxs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(2, 1fr)'
          }}
          columnGap={{ xxxs: 0, sm: '32px' }}
          rowGap={{ xxxs: '16px', sm: '32px' }}
        >
          {allAtendeSchedule.nodes.map(
            ({ id, title, customFields: { data, descricao } }) => (
              <ScheduleCard
                key={id}
                title={title}
                date={data}
                description={descricao}
              />
            )
          )}
        </Block>
        <Block marginTop={40} textAlign="center">
          <Button
            tag={Link}
            title="Faça já sua inscrição"
            to="#fazer-inscricao"
            color="acelera100"
            weight="semiBold"
            marginRight={16}
            size="lg"
          >
            Faça já sua inscrição
          </Button>
        </Block>
      </Container>
    </S.SectionSchedule>
  )
}

export default SectionSchedule
