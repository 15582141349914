import { useConfig } from 'hooks'
import React, { useEffect } from 'react'

interface ConfigProps {
  data: Record<string, any>
}

const Config: React.FC<ConfigProps> = ({ data }) => {
  const { setConfig } = useConfig()

  useEffect(() => {
    setConfig(data)
  }, [])

  return null
}

export default Config
