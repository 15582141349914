import React, { AriaAttributes } from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'

import * as S from './Link.styled'

export interface LinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>,
    AriaAttributes {
  googleEvent?: string
}

const Link: React.FC<LinkProps> = ({
  children,
  to,
  className = '',
  activeClassName = 'active',
  partiallyActive = false,
  ...rest
}) => {
  const internal = /^\/(?!\/)/.test(to)
  const file = /\.[0-9a-z]+$/i.test(to)

  if (internal && !file) {
    const toWithSlash = to
    // to.endsWith('/') ? to : `${to}/`

    return (
      <GatsbyLink
        to={toWithSlash}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <S.Link className={className} href={to} {...rest}>
      {children}
    </S.Link>
  )
}

export default Link
