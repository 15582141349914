import { hasKey, parseColor } from '@superlogica/design-ui'
import { DefaultTheme } from 'styled-components'

export const parseThemeColor = (color: Color, theme: DefaultTheme) => {
  if (hasKey(color, theme.colors)) {
    return parseColor(theme.colors[color])
  }

  return theme.campaignColors[color]
}
