import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const Figure = styled.div``

export const Header = styled.header``

export const Wrapper = styled.div``

export const InnerWrapper = styled.div`
  position: relative;
`
export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`

export const SectionIntro = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  .formWrapper {
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 100vw;
      height: 40px;
      position: absolute;
      bottom: -40px;
    }

    &:before {
      left: -100vw;
    }

    &:after {
      left: 0;
    }
  }

  .planes {
    position: absolute;
    top: -40px;
    right: -40px;
    width: 35%;
    opacity: 0.8;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.violet300)};

    .formWrapper {
      &:before {
        background-color: ${parseColor(colors.babar200)};
      }
      &:after {
        background-color: ${parseColor(colors.acelera700)};
      }
    }
  `}

  ${media.lessThan('sm')} {
    padding: 60px 0 40px;
  }
`

export const SectionAbout = styled.section`
  overflow: hidden;

  ${Figure} {
    width: calc(100% + 60px);
    transform: translateX(-60px);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100vw;
      height: 100%;
      right: 100%;
    }

    .gatsby-image-wrapper {
      overflow: hidden;

      * {
        border-bottom-left-radius: 100px;
      }
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper25)};

    ${Figure} {
      background-color: ${parseColor(colors.acelera300)};

      &:before {
        background-color: ${parseColor(colors.acelera500)};
      }
    }
  `}

  ${media.lessThan('sm')} {
    ${Figure} {
      width: calc(100% + 2rem);
      transform: translateX(0);
    }
  }
`

export const SectionNumbers = styled.section`
  padding: 80px 0;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper50)};
  `}

  ${media.lessThan('md')} {
    padding: 60px 0;
  }
`

export const SectionVideo = styled.section`
  padding: 80px 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #210754 11.63%, #6f0cf2 86.18%);
  position: relative;

  .planes {
    position: absolute;
    top: 0;
    width: 10%;
    height: 100%;
    opacity: 0.8;
  }

  .planes:nth-child(1) {
    left: 50px;
  }

  .planes:nth-child(2) {
    right: 50px;
  }

  ${media.lessThan('md')} {
    .planes:nth-child(1) {
      left: 20px;
    }

    .planes:nth-child(2) {
      right: 20px;
    }
  }

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`

export const SectionGrowth = styled.section`
  overflow: hidden;
  padding-bottom: 80px;

  ${InnerWrapper} {
    flex: 1;
    padding: 60px 40px 60px 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
    }
    &:before {
      width: 100vw;
      left: -100vw;
    }
    &:after {
      width: 60px;
      right: -60px;
      border-bottom-right-radius: 32px;
    }
  }

  ${Wrapper} {
    flex: 2;
    padding: 40px 60px;
    border-left: 16px solid;
    border-bottom-right-radius: 32px;
    z-index: 2;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.acelera900)};

    ${InnerWrapper} {
      background-color: ${parseColor(colors.acelera700)};

      &:before,
      &:after {
        background-color: inherit;
      }
    }
    ${Wrapper} {
      background-color: ${parseColor(colors.trooper0)};
      border-color: ${parseColor(colors.warning)};
    }
  `}

  ${media.lessThan('md')} {
    padding-bottom: 60px;

    ${InnerWrapper} {
      flex: 1;
      padding: 60px 20px 60px 0;
    }
    ${Wrapper} {
      flex: 1;
      margin-top: -32px;
      padding: 24px;
    }
  }
`

export const SectionSchedule = styled.section`
  padding: 80px 0;

  ${({theme: { colors }}) => css`
    background-color: ${parseColor(colors.acelera700)}
  `}
`

export const SectionTestimonies = styled.section`
  padding: 80px 0;
  overflow: hidden;

  .sdl-carousel {
    .swiper-pagination {
      bottom: -50px;
    }
    .swiper-slide {
      height: auto;
    }
  }

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`

export const SectionEvents = styled.section`
  padding: 80px 0;
  overflow: hidden;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper25)};
  `}

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`

export const SectionConversion = styled.section`
  position: relative;
  padding: 80px 0;
  overflow: hidden;

  .planes {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: -3vw;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.violet300)};
  `}

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`
