/* eslint-disable prettier/prettier */
import AccountingCoins from './accounting-coins.svg'
import AnalyticsGraphBar from './analytics-graph-bar.svg'
import AnalyticsGraph from './analytics-graph.svg'
import ArrowDown from './arrow-down.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRightCircle from './arrow-right-circle.svg'
import ArrowRight from './arrow-right.svg'
import ArrowUp from './arrow-up.svg'
import BrandColor from './brand-color.svg'
import Brand from './brand.svg'
import BusinessContractHandshakeSign from './business-contract-handshake-sign.svg'
import CalendarCheck from './calendar-check.svg'
import CashPaymentCoin from './cash-payment-coin.svg'
import Check from './check.svg'
import Close from './close.svg'
import CommonFileTextShare from './common-file-text-share.svg'
import ConversationChatBubble from './conversation-chat-bubble.svg'
import Copy from './copy.svg'
import CurrencyDollarIncrease from './currency-dollar-increase.svg'
import CustomerRelationshipManagementTeamAssignment from './customer-relationship-management-team-assignment.svg'
import DataFileBarsSettings from './data-file-bars-settings.svg'
import DoubleArrowUp from './double-arrow-up.svg'
import EcologyHumanMind from './ecology-human-mind.svg'
import Email from './email.svg'
import ExternalLink from './external-link.svg'
import Flow2 from './flow-2.svg'
import Megaphone from './megaphone.svg'
import MessagesPeopleUserCheck from './messages-people-user-check.svg'
import MonetizationUserLaptop from './monetization-user-laptop.svg'
import MultipleActionsStar from './multiple-actions-star.svg'
import PaperShare from './paper-share.svg'
import PeoplePaperCheck from './people-paper-check.svg'
import PerformanceIncrease2 from './performance-increase-2.svg'
import PlanesConversion from './planes-conversion.svg'
import PlanesHero2 from './planes-hero-2.svg'
import PlanesHero from './planes-hero.svg'
import PlanesIntro from './planes-intro.svg'
import PlanesVideo from './planes-video.svg'
import PlanesVideo2 from './planes-video2.svg'
import Play from './play.svg'
import PlusSquare from './plus-square.svg'
import Quote from './quote.svg'
import RealEstateMarketBuilding from './real-estate-market-building.svg'
import Sebrae from './sebrae.svg'
import SingleArrowUp from './single-arrow-up.svg'
import SingleNeutralIncrease from './single-neutral-increase.svg'
import Sound from './sound.svg'
import SuperlogicaAcelera from './superlogica-acelera.svg'
import SuperlogicaSebrae from './superlogica-sebrae.svg'
import TargetCenter from './target-center.svg'
import Target from './target.svg'
import TeamExchangeChat from './team-exchange-chat.svg'
import Triangle from './triangle.svg'
import UpCharts from './up-charts.svg'
import UserIdea from './user-idea.svg'
import User from './user.svg'

const vectors = {
  'accounting-coins': AccountingCoins,
  'analytics-graph-bar': AnalyticsGraphBar,
  'analytics-graph': AnalyticsGraph,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right-circle': ArrowRightCircle,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'brand-color': BrandColor,
  'brand': Brand,
  'business-contract-handshake-sign': BusinessContractHandshakeSign,
  'calendar-check': CalendarCheck,
  'cash-payment-coin': CashPaymentCoin,
  'check': Check,
  'close': Close,
  'common-file-text-share': CommonFileTextShare,
  'conversation-chat-bubble': ConversationChatBubble,
  'copy': Copy,
  'currency-dollar-increase': CurrencyDollarIncrease,
  'customer-relationship-management-team-assignment': CustomerRelationshipManagementTeamAssignment,
  'data-file-bars-settings': DataFileBarsSettings,
  'double-arrow-up': DoubleArrowUp,
  'ecology-human-mind': EcologyHumanMind,
  'email': Email,
  'external-link': ExternalLink,
  'flow-2': Flow2,
  'megaphone': Megaphone,
  'messages-people-user-check': MessagesPeopleUserCheck,
  'monetization-user-laptop': MonetizationUserLaptop,
  'multiple-actions-star': MultipleActionsStar,
  'paper-share': PaperShare,
  'people-paper-check': PeoplePaperCheck,
  'performance-increase-2': PerformanceIncrease2,
  'planes-conversion': PlanesConversion,
  'planes-hero-2': PlanesHero2,
  'planes-hero': PlanesHero,
  'planes-intro': PlanesIntro,
  'planes-video': PlanesVideo,
  'planes-video2': PlanesVideo2,
  'play': Play,
  'plus-square': PlusSquare,
  'quote': Quote,
  'real-estate-market-building': RealEstateMarketBuilding,
  'sebrae': Sebrae,
  'single-arrow-up': SingleArrowUp,
  'single-neutral-increase': SingleNeutralIncrease,
  'sound': Sound,
  'superlogica-acelera': SuperlogicaAcelera,
  'superlogica-sebrae': SuperlogicaSebrae,
  'target-center': TargetCenter,
  'target': Target,
  'team-exchange-chat': TeamExchangeChat,
  'triangle': Triangle,
  'up-charts': UpCharts,
  'user-idea': UserIdea,
  'user': User,
}

export type VectorsProps = keyof typeof vectors

export default vectors