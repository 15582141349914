import React from 'react'

import { appConfig } from 'config'
import { Config, Seo } from 'components/helpers'

import View from 'views/error'

const NotFoundPage: React.FC = () => (
  <>
    <Config data={appConfig} />
    <Seo title="Página não encontrada" />
    <View />
  </>
)

export default NotFoundPage
