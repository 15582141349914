import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'

import * as S from './styled'

const View: React.FC = () => (
  <Block tag="section" bgColor="babar25">
    <S.Header>
      <Container>
        <Row justify="center">
          <Col xs={12} md={10}>
            <Title size="display02" font="heading" color="babar0">
              Termos de uso
            </Title>
            <Text color="babar0" weight="semiBold">
              Por favor leia estes termos de uso com cuidado, antes de acessar o
              programa.
            </Text>
          </Col>
        </Row>
      </Container>
    </S.Header>
    <Block
      tag="article"
      paddingTop={{ xxxs: 40, md: 60 }}
      paddingBottom={{ xxxs: 40, md: 60 }}
    >
      <Container>
        <Row justify="center">
          <Col xs={12} md={10}>
            <Text marginBottom={16} weight="semiBold">
              Você aceita os nossos termos de uso. Se você não concorda com
              estes Termos de Uso do programa, por favor não os faça.
            </Text>
            <Text marginBottom={40} weight="semiBold">
              O seu acesso e uso deste site, assim como as páginas relacionadas
              da SUPERLÓGICA Acelera o qual inclui acelera.superlogica.com está
              sujeita aos termos e condições abaixo. Ao navegar e acessar o
              nosso site você aceita, sem qualquer limitação, os nossos Termos
              de Uso e reconhece todos os pontos abaixo:
            </Text>
            <S.List>
              <S.Item>
                <S.Text>
                  Todo o conteúdo como textos, dados, gráficos, imagens,
                  arquivos para download, fotos, vídeos e música são materiais
                  pertencentes da Superlógica. Nada pode ser utilizado ou
                  reproduzido.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Enquanto fazemos o nosso melhor para manter atualizada a
                  informação nas nossas páginas, a Superlógica não assume
                  qualquer responsabilidade quanto a erros e emissões de
                  conteúdo.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Quando você se registra em nosso site - seja como cliente ou
                  apenas como um contato -, você expressa total aprovação para
                  receber informações eletrônicas, ofertas e promoções
                  referentes aos nossos produtos e serviços.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Todas as informações pessoais submetidas no decorrer do uso do
                  Site estarão sujeitas à Política de Privacidade, cujos termos
                  devem ser lidos para o melhor entendimento das práticas do
                  Grupo Superlógica.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Ao participar do programa Acelera, o participante fica
                  proibido de compartilhar o seu login com outras pessoas ou
                  exibir o conteúdo para outras pessoas que não sejam
                  inscrito(s) no programa. Caso a Superlógica encontre qualquer
                  discordância com os dados de acesso, ela tem o direito de
                  banir o(a) usuário(a), sem ter que pagar qualquer valor por
                  isso. A participação no programa é pessoal e não pode ser
                  compartilhada.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  A Superlógica permite que você use até 4 (quatro) aparelhos,
                  acreditamos que isso seja o suficiente pra uma pessoa pois
                  permite usar smartphones, tablets e diferentes computadores
                  (do trabalho e pessoal).
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  A Superlógica está livre de aceitar ou rejeitar qualquer tipo
                  de cliente em nossa base de dados ou participantes. Não somos
                  obrigados a mantermos relações comerciais com pessoas ou
                  empresas nas quais não tenhamos interesses.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Você concorda em permitir o uso de cookies em todos os seus
                  devices para quaisquer fins que a Superlógica julgar válido.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Cadastrar-se ao Site, escolher e assistir cursos gratuitos,
                  são caracterizados como serviços gratuitos, isto é, sem
                  custos.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Exceto quando expressamente autorizado pela Superlógica ou por
                  estes termos, o usuário não poderá copiar, reproduzir,
                  publicar, distribuir, difundir, transmitir, distribuir,
                  modificar, criar trabalhos derivados, alugar, arrendar,
                  vender, ceder, sub-licenciar, transferir, exibir, transmitir,
                  compilar ou recolher em um banco de dados, ou de qualquer
                  forma explorar comercialmente o conteúdo do Site, do Conteúdo
                  da Empresa ou dos Serviços, no todo ou em parte. O usuário não
                  vai, de qualquer maneira, sem autorização prévia por escrito
                  da Superlógica, descompilar, desmontar, fazer engenharia
                  reversa, montagem reversa ou tentar descobrir o código-fonte,
                  o Software ou qualquer conteúdo da empresa, o Site ou os
                  Serviços. O usuário não pode armazenar qualquer parcela
                  significativa de qualquer conteúdo Companhia ou os Serviços de
                  qualquer forma, seja por meio de arquivos, arquivos legíveis
                  por computador ou qualquer outro meio. O usuário não pode
                  "espelhar" qualquer conteúdo da empresa ou dos serviços em
                  qualquer servidor. Qualquer uso não autorizado ou proibido do
                  software, o Conteúdo da Empresa, o Site ou os Serviços, coloca
                  o infrator sujeito à responsabilidade civil e ação penal nas
                  leis federais e estaduais.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Como alguém que busca conteúdo educacional, o usuário concorda
                  que:
                </S.Text>
                <S.SubList>
                  <S.Item>
                    Lerá e respeitará as informações de preços (ver seção
                    “Serviço”) antes de usar o Site;
                  </S.Item>
                  <S.Item>
                    Se for menor de 18 (dezoito) anos, obterá o consentimento
                    dos pais ou responsáveis legais antes de utilizar o site e
                    antes de entrar em contato com Tutores e Escolas.
                  </S.Item>
                  <S.Item>Não manipulará ou interferirá no Site;</S.Item>
                  <S.Item>
                    Não solicitará informações pessoais de qualquer Tutor ou
                    Escola, e concordará que, se algum Tutor ou Escola divulgar
                    tais informações para você, e lhe sugerir uma conversa
                    pessoal ou offline ou se lhe for solicitado qualquer
                    informação pessoal, concorda que informará ao Site de
                    imediato, por escrito ou telefone.
                  </S.Item>
                </S.SubList>
              </S.Item>
              <S.Item>
                <S.Text>
                  Em relação ao seu uso dos Serviços, o usuário concorda em (a)
                  fornecer informações verdadeiras, exatas, atuais e completas
                  sobre si mesmo, conforme solicitado pelo formulário de
                  registro no Serviço (tais informações: "Seus Dados"), (b)
                  manter e atualizar prontamente Seus Dados para mantê-los
                  verdadeiros, exatos, atuais e completos, e (c) cumprir os
                  presentes Termos. Se o usuário fornecer qualquer informação
                  que seja falsa, inexata, não atual ou incompleta, ou se
                  acreditar-se que tal informação é falsa, inexata, não atual ou
                  incompleta, a Superlógica Acelera se reserva no direito de
                  suspender ou encerrar a conta(s) do usuário(s) e recusar ou
                  restringir qualquer uso futuro dos Serviços.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  O usuário pode baixar e imprimir um número razoável de cópias
                  da documentação fornecida ou disponível no Conteúdo da Empresa
                  para uso pessoal ou educacional não comercial, enquanto esses
                  termos estão em pleno vigor e efeito, desde que (i) todas as
                  cópias autorizadas de documentação fornecida ou disponível em
                  conexão com o Conteúdo da Empresa contêm, em uma forma
                  inalterada, em (a) todas as designações de linguagem contida
                  no material originalmente fornecidas para o usuário pela
                  Superlógica, indicando a natureza confidencial das mesmas e
                  (b) todos os direitos autorais ou outros direitos de
                  propriedade contidos nos materiais inicialmente previstos para
                  que por nós e uma atribuição fonte original para Superlógica e
                  / ou as partes aplicáveis à Terceiros, e (ii) o usuário não
                  irá modificar de qualquer um dos conteúdos da empresa, exceto
                  aprovado pela Superlógica com antecedência por escrito. O
                  usuário reconhece que a Superlógica e/ou Terceiros, conforme o
                  caso, mantém todos os direitos, títulos e interesses relativos
                  a todos os aspectos tangíveis e intangíveis do Conteúdo da
                  Empresa, o Site e os Serviços, incluindo, sem limitação, todas
                  as patentes, direitos autorais e segredos comerciais eles
                  relacionados, e que, com exceção dos direitos limitados
                  estabelecidos acima, não adquirindo qualquer direito de
                  propriedade intelectual ou licença em qualquer um dos
                  anteriores por download ou imprimindo o conteúdo da empresa ou
                  de outra forma, incluindo, sem limitação, o acesso ou
                  utilização do Site, o Conteúdo da Empresa ou dos Serviços.
                  Estes direitos concedidos ao usuário são revogáveis pela
                  Superlógica de acordo com estes Termos.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  A Superlógica não promove, fomenta ou tolera a cópia de
                  material protegido por direitos autorais ou qualquer outra
                  atividade ilícita. Se o usuário acredita que seu trabalho foi
                  copiado de uma forma que constitua violação de direitos
                  autorais ou que seus direitos de propriedade intelectual
                  violados, deverá enviar à Superlógica um aviso com as
                  seguintes informações:
                </S.Text>
                <S.SubList>
                  <S.Item>
                    uma assinatura eletrônica ou física da pessoa autorizada a
                    agir em nome do proprietário dos direitos autorais ou outro
                    interesse de propriedade intelectual;
                  </S.Item>
                  <S.Item>
                    uma descrição dos trabalhos protegidos por direitos autorais
                    ou outra propriedade intelectual que o usuário reclama ter
                    sido infringida;
                  </S.Item>
                  <S.Item>
                    uma descrição de onde o material que o usuário alega estar
                    infringindo está localizado no Site;
                  </S.Item>
                  <S.Item>
                    seu endereço, número de telefone e endereço eletrônico;
                  </S.Item>
                  <S.Item>
                    uma declaração sua de que o usuário tem uma crença de boa fé
                    que o uso contestado não seja autorizado pelo proprietário
                    dos direitos autorais, seu agente ou pela lei;
                  </S.Item>
                  <S.Item>
                    uma declaração sua, feita sob pena de perjúrio, que as
                    informações acima em seu anúncio para Superlógica é preciso
                    e que o usuário é o dono da propriedade intelectual ou
                    direitos autorais ou autorizado a agir em nome do autor ou
                    proprietário de propriedade intelectual.
                  </S.Item>
                </S.SubList>
                <Text marginTop={20}>
                  Informe pelo email:{' '}
                  <a
                    href="mailto:mktcondo@superlogica.com"
                    title="mktcondo@superlogica.com"
                  >
                    mktcondo@superlogica.com
                  </a>
                </Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Informações Confidenciais
                  <br /> O usuário concorda em proteger o Conteúdos da Empresa e
                  dos Serviços ("Informação Privilegiada") e para prevenir o uso
                  indevido, negligente ou acidental a divulgação de tal
                  informação confidencial. O usuário não vai, sem o
                  consentimento prévio da Superlógica e por escrito, diretamente
                  ou indiretamente, utilizar ou divulgar a informação
                  privilegiada a qualquer pessoa ou entidade comercial, exceto
                  para um número limitado de funcionários que estão em uma
                  necessidade de tomar conhecimento e quem concordar por escrito
                  com estar vinculado a restrições de uso e divulgação
                  estabelecidos nestes Termos. O usuário concorda em notificar,
                  por escrito, a Superlógica de qualquer uso ou divulgação de
                  informações privadas em violação destes Termos. O usuário
                  reconhece que a utilização ou divulgação das informações
                  protegidas de qualquer maneira inconsistente com estes Termos
                  irá nos causar danos irreparáveis e que teremos o direito de
                  (i) redução equitativa e cautelar para impedir o uso proibido
                  ou divulgação dessas informações, e (ii) recuperar o montante
                  de todos os danos (incluindo honorários advocatícios e
                  despesas) em relação ao uso proibido ou divulgação dessas
                  informações.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  O Site ou os Serviços podem fornecer links para outros sites
                  de terceiros (os "Sites de Terceiros"). Isso pode incluir o
                  envio de links de Tutores ou Escolas para sites de Terceiros
                  (tais como recursos de estudo ou páginas de educação on-line).
                  Como a Superlógica não tem controle sobre Sites de Terceiros,
                  o usuário reconhece e concorda que a Superlógica não é
                  responsável pela disponibilidade de Sites de Terceiros, e não
                  endossa e não é responsável por qualquer conteúdo,
                  publicidade, produtos, serviços ou outros materiais
                  disponíveis em sites de terceiros. O Usuário reconhece e
                  concorda que não será responsável, direta ou indiretamente,
                  por quaisquer danos ou perdas causados ou alegadamente
                  causados por ou em conexão com o uso ou dependência de
                  qualquer conteúdo, publicidade, produtos, serviços ou outras
                  matérias disponíveis no ou através de Sites de Terceiros ou
                  por qualquer erro, difamação, calúnia, omissão, falsidade,
                  obscenidade, pornografia, palavrões ou que eles possam conter.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  As marcas registradas, marcas de serviço e os logotipos (as
                  "Marcas") usadas e apresentadas no Site ou em qualquer
                  Conteúdo da Empresa são marcas registradas ou não, da
                  Superlógica e outros, e são protegidos, sem limitação, de
                  acordo com as leis brasileiras e de marca estrangeira. Nada no
                  Site, nos Serviços devem ser interpretados como concessão, por
                  implicação, preclusão ou de outra forma, qualquer licença ou
                  direito de usar qualquer Marca apresentada neste Site ou em
                  conexão com os Serviços de Conteúdo da Empresa, sem a escrita
                  permissão do proprietário da marca registrada. A Superlógica
                  agressivamente faz valer os seus direitos de propriedade
                  intelectual em toda a extensão da lei. O usuário não poderá
                  usar as Marcas, sejam da Superlógica ou de outros, de qualquer
                  maneira sem a permissão prévia por escrito do proprietário da
                  marca registrada. A Superlógica proíbe o uso do logotipo como
                  um "link" para qualquer outro site da internet, salvo se
                  aprovado com antecedência por escrito.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Isenção de Garantia
                  <br /> O conteúdo da empresa, do site, dos serviços e de cada
                  parte deles são fornecidos "como está" sem garantia de
                  qualquer tipo, expressa ou implícita. ao limite máximo do
                  possível direito aplicável, rejeitam todas as garantias,
                  expressas ou implícitas, com relação ao site, o conteúdo da
                  empresa, dos serviços e cada parte deles, incluindo, mas não
                  se limitando às garantias de comercialização, adequação para
                  um determinado fim, não-violação ou outra violação de
                  direitos. A Superlógica não garante nem faz declarações sobre
                  o uso, exatidão, vigência, ou de confiança ou dos resultados
                  do uso, ou qualquer outra situação do conteúdo da empresa, do
                  site, dos serviços de cada parte deles ou sites de terceiros.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Em nenhuma hipótese, incluindo, mas não se limitando a,
                  negligência, a superlógica ou terceiros, serão responsáveis
                  por quaisquer danos indiretos, especiais, fortuitos ou,
                  incluindo, mas não se limitando a, perda de dados ou lucros,
                  decorrentes ou relativos ao uso ou a incapacidade de uso do
                  conteúdo da empresa, do site, dos serviços ou qualquer parte
                  do mesmo, mesmo que o representante autorizado tenha sido
                  avisado da possibilidade de tais danos. se a sua utilização do
                  site, o conteúdo da empresa, serviços ou qualquer parte do
                  mesmo, resultar na necessidade de reparação, manutenção ou
                  correção de equipamentos ou dados, o usuário assume qualquer
                  custo do mesmo. nem a superlógica, nem terceiros garantem a
                  exatidão da informação, textos, gráficos, links ou outros
                  itens constantes do conteúdo da empresa, do site, dos serviços
                  ou qualquer parte do mesmo ou em todos os relatórios de
                  serviços de verificação. O usuário concorda em não
                  responsabilizar a superlógica (ou os seus agentes, empregados,
                  tutores ou escolas) por qualquer instrução, consultoria, ou
                  serviços prestados que se originaram através do site, através
                  de qualquer serviço de verificação ou em ligação com o
                  conteúdo da empresa, serviços ou qualquer parte do mesmo. não
                  será a superlógica responsável por conflitos, reclamações,
                  perdas, lesões ou danos de qualquer natureza que possam
                  decorrer do ou se relacionam com conduta de tutores, escolas
                  ou usuários.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  O usuário concorda em indenizar, defender e isentar a
                  Superlógica, e nossas afiliadas, administradores, diretores,
                  agentes, parceiros, colaboradores, licenciados,
                  representantes, fornecedores e terceiros (incluindo
                  funcionários de nossos afiliados, diretores, agentes,
                  parceiros, colaboradores, licenciados, representantes e
                  fornecedores de terceiros), de todas as perdas, despesas,
                  danos, custos, reivindicações e demandas, incluindo honorários
                  advocatícios razoáveis e custos e despesas, devido a ou
                  resultante de qualquer Conteúdo Submetido que o usuário
                  enviar, postar, enviar e-mail, ou transmitir ou através dos
                  Serviços, o uso dos Serviços, o Conteúdo da Empresa ou
                  qualquer parte dele, a sua ligação com os Serviços, ou sua
                  violação destes Termos. A Superlógica se reserva no direito
                  de, às suas expensas, assumir a defesa e o controle exclusivos
                  de qualquer assunto sujeito a indenização pelo usuário e em
                  tal caso, o usuário concorda em cooperar plenamente com essa
                  defesa e reivindicar quaisquer defesas disponíveis.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  A Superlógica se reserva no direito, quando necessário, com ou
                  sem aviso prévio, de alterar estes Termos a nosso critério
                  exclusivo e absoluto. A versão mais recente destes Termos pode
                  ser revista, clicando em "Termos de Serviço", localizado na
                  parte inferior das páginas do site. A versão mais atual dos
                  Termos substituirá todas as versões anteriores. O uso do site
                  ou do uso continuado dos serviços após as alterações significa
                  que o usuário concorda em ficar vinculado por tais alterações.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Se qualquer disposição destes Termos for considerada ilegal ou
                  inaplicável, estes Termos serão considerados reduzidos na
                  medida do necessário para fazer o ponto de vista jurídico e
                  executivo e continuará a ser modificado.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  É eleito o Foro Central da Comarca de São Paulo como
                  competente para dirigir quaisquer controvérsias oriundas do
                  presente Termo, com renúncia expressa a qualquer outro.
                </S.Text>
              </S.Item>
              <S.Item>
                <S.Text>
                  Qualquer notificação ou outra comunicação a ser nos Termos do
                  presente será dada por escrito, por fax ou recibo de entrega
                  de certificados, ou correio eletrônico.
                </S.Text>
              </S.Item>
            </S.List>
            <Text>© 2023 Superlógica Acelera</Text>
          </Col>
        </Row>
      </Container>
    </Block>
  </Block>
)

export default View
