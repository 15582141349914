import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Carousel } from '@superlogica/design-ui'

import { Testimony } from 'components/app'

import * as S from './styled'

interface TestimoniesDataProps {
  allTestimoniesJson: {
    nodes: {
      body: string
      author: {
        name: string
        role: string
      }
    }[]
  }
}

const SectionTestimonies = () => {
  const {
    allTestimoniesJson: { nodes: testimonies }
  } = useStaticQuery<TestimoniesDataProps>(
    graphql`
      query {
        allTestimoniesJson(filter: { category: { eq: "event" } }) {
          nodes {
            body
            author {
              name
              role
            }
          }
        }
      }
    `
  )

  return (
    <S.SectionTestimonies>
      <Container>
        <Block tag="header" marginBottom={40}>
          <Title
            color="acelera500"
            weight="bold"
            font="heading"
            size="display02"
            tag="h2"
          >
            Quem participa, recomenda.
          </Title>
          <Text color="acelera500" font="heading" size="display02">
            Está esperando o que para se juntar a eles?
          </Text>
        </Block>
        <Block tag="article">
          <Carousel
            fullMobile
            bulletColor="acelera500"
            activeBulletColor="acelera500"
            swiper={{
              initialSlide: 0,
              observer: true,
              spaceBetween: 64,
              watchOverflow: true,
              simulateTouch: false,
              centeredSlides: false,
              centerInsufficientSlides: true,
              pagination: {
                clickable: true
              },
              slidesPerView: 3,
              breakpoints: {
                0: {
                  slidesPerView: 1
                },
                960: {
                  slidesPerView: 2
                },
                1280: {
                  slidesPerView: 3
                }
              }
            }}
          >
            {testimonies.map(({ author, body }) => (
              <Testimony key={author.name} body={body} author={author} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </S.SectionTestimonies>
  )
}

export default SectionTestimonies
